import * as React from 'react';

interface Props {
    className?: string;
}

export const SadSmile: React.FC<Props> = ({ className }) => {
    return (
        <svg
            enableBackground="new 0 0 295.996 295.996"
            version="1.1"
            viewBox="0 0 296 296"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            fill="currentColor"
        >
            <path d="m148 0c-81.606 0-148 66.392-148 148s66.392 148 148 148 148-66.392 148-148-66.391-148-148-148zm0 280c-36.257 0-69.143-14.696-93.023-38.44-9.536-9.482-17.631-20.41-23.934-32.42-9.599-18.289-15.041-39.089-15.041-61.138 0-72.784 59.214-132 132-132 34.523 0 65.987 13.328 89.533 35.102 12.208 11.288 22.289 24.844 29.558 39.996 8.27 17.239 12.907 36.538 12.907 56.9 0 72.784-59.214 132-132 132zm-55.866-3.602c0.24 0.105 0.48 0.212 0.722 0.316-0.242-0.105-0.482-0.211-0.722-0.316z" />
            <circle cx="98.497" cy="116" r="16" />
            <circle cx="197.5" cy="116" r="16" />
            <rect
                transform="matrix(.9176 .3974 -.3974 .9176 45.826 -76.324)"
                x="179.45"
                y="64.405"
                width="55.22"
                height="16"
            />
            <rect
                transform="matrix(-.9176 .3974 -.3974 -.9176 199.32 103.5)"
                x="61.324"
                y="64.405"
                width="55.219"
                height="16"
            />
            <path d="m214.25 202.78c1.228 2.176 2.358 4.438 3.363 6.724l14.648-6.436c-1.218-2.771-2.589-5.513-4.075-8.148-17.022-30.189-50.407-48.542-85.014-46.744-34.383 1.779-65.563 23.325-79.435 54.892l14.648 6.438c11.461-26.08 37.215-43.881 65.613-45.351 28.586-1.483 56.186 13.679 70.252 38.625z" />
        </svg>
    );
};
