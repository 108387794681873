import { Page } from 'components/shared/layout/Page/Page';
import { Link } from 'components/shared/Link/Link';
import { TextPlain } from 'components/shared/TextPlain/TextPlain';
import * as React from 'react';
import styles from './CopyrightHolders.module.scss';

export const CopyrightHolders = () => {
    const emailLink = (
        <Link href="mailto:achord.ru@gmail.com" rel="noreferrer" className={styles.link}>
            achord.ru@gmail.com
        </Link>
    );

    return (
        <Page hideSheetsNav>
            <TextPlain className={styles.title}>Правообладателям</TextPlain>
            <TextPlain className={styles.content}>
                <p>
                    Текстовые, фото-, видео и иные материалы опубликованный на сайте носят
                    исключительно ознакомительных характер, получены редакцией сайта из источников
                    свободного распространения, и материалов в сети интернет на основании
                    «добросовестного использования» (fair use).
                </p>
                <p>
                    Согласно законодательству Российской Федерации, термин «добросовестное
                    использование» регулируется статьями закона № 5351-1 от 09.07.1993 г. «Об
                    авторском праве и смежных правах» (далее — Закон):
                </p>
                <ul className={styles.listing}>
                    <li>
                        Статья 19. Использование произведения без согласия автора и без выплаты
                        авторского вознаграждения
                    </li>
                    <li>Статья 20. Использование произведений путем репродуцирования</li>
                    <li>
                        Статья 21. Свободное использование произведений, постоянно расположенных в
                        местах, открытых для свободного посещения
                    </li>
                    <li>Статья 22. Свободное публичное исполнение</li>
                </ul>

                <div className={styles.head1}>Сведения для правообладателей</div>
                <p>
                    В случае если Вами был обнаружен материал, на который распространяется Ваше
                    авторское право, подкрепленное Законом, и вы не желаете распространения
                    материала на сайте без личного согласия либо без такового, наша редакция примет
                    незамедлительные меры и окажет содействие по удалению или корректировке
                    материала (-лов) в зависимости от Вашего предпочтения.
                </p>

                <p>
                    В таком случае правообладателю необходимо направить письмо на электронную почту{' '}
                    {emailLink}, со следующей информацией:
                </p>

                <ul className={styles.listing}>
                    <li>
                        скан копию документа (-тов), которые подтверждают Ваши права на материал,
                        защищенный авторским правом (с реквизитами, печатью, и подписями должностных
                        лиц), или
                    </li>
                    <li>
                        электронную почту, с домена компании имеющую официальный статус
                        правообладателя, или
                    </li>
                    <li>
                        иную контактную информацию, которая позволит точно и однозначно провести
                        идентификацию Вас как правообладателя материала;
                    </li>
                    <li>подтвердить исключительные права на материал;</li>
                    <li>
                        прямые ссылки на источники Сайта, где опубликован материал защищенный
                        авторским правом, для его удаления, корректировки.
                    </li>
                    <p>
                        Редакция сайта в течении 48 часов с момента поступления электронного письма
                        в случае идентификации вас как правообладателя обязуется удалить материал
                        защищенный авторским правом, либо уведомить заявителя о нюансах в результате
                        которых не удалось провести идентификацию.
                    </p>
                </ul>

                <div className={styles.head1}>Обращаем ваше внимание</div>
                <ol className={styles.listing}>
                    <li>
                        Редакция сайта оставляет за собой полное право на публикацию любого
                        материала присланного через контактную форму или загруженного
                        зарегестрированным пользователем.
                    </li>
                    <li>
                        Согласно Закону ссылки на любые данные либо информационные сообщения, сами
                        по себе, не подходят под понятие объекта авторского права
                    </li>
                </ol>

                <p>
                    Исходя из выше сказанного, хотим уведомить, что письма с угрозами прямыми либо
                    косвенными, и (или) требования не имеющие правовых оснований для содействия
                    будут игнорироваться.
                </p>
            </TextPlain>
        </Page>
    );
};
