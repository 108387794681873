import * as React from 'react';

interface Props {
    className?: string;
}

export const InstagramIcon: React.FC<Props> = ({ className }) => {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 200 200"
            width="25"
            height="25"
            className={className}
        >
            <path
                d="M-.3-117.871c0 64.6-52.3 116.9-116.9 116.9h-278.2c-64.6 0-116.9-52.3-116.9-116.9v-278.1c0-64.6 52.4-117 117-117H-119c65.6 0 118.7 53.1 118.7 118.7v276.4z"
                fill="currentColor"
                transform="matrix(.39 0 0 .39 200 200)"
            />
            <path
                d="M129.143 28.809H73.607c-24.609 0-44.577 19.968-44.577 44.577v55.497c0 24.609 19.929 44.538 44.577 44.538h55.497c24.609 0 44.538-19.929 44.538-44.538V73.386c.039-24.648-19.89-44.577-44.499-44.577zm30.654 94.731c0 19.89-16.107 35.997-35.997 35.997H78.95c-19.89 0-35.997-16.107-35.997-35.997V78.69c0-19.89 16.107-35.997 35.997-35.997h44.85c19.89 0 35.997 16.146 35.997 35.997v44.85zm-56.471-60.258c-21.06 0-38.103 17.043-38.103 38.103 0 21.021 17.043 38.103 38.103 38.103 21.021 0 38.103-17.043 38.103-38.103-.039-21.06-17.082-38.103-38.103-38.103zm0 60.996c-12.675 0-22.932-10.257-22.932-22.932s10.257-22.932 22.932-22.932c12.636 0 22.932 10.257 22.932 22.932-.039 12.675-10.296 22.932-22.932 22.932zm45.182-61.643c0 5.343-4.329 9.672-9.672 9.672s-9.672-4.329-9.672-9.672 4.329-9.711 9.672-9.711 9.672 4.329 9.672 9.711z"
                fill="#fff"
            />
        </svg>
    );
};
