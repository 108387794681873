import * as React from 'react';

interface Props {
    className?: string;
}

export const WhatsAppIcon: React.FC<Props> = ({ className }) => {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 40 40"
            width="25"
            height="25"
            className={className}
        >
            <g fill="currentColor">
                <path d="M.895 39.33l2.694-9.835a18.94 18.94 0 0 1-2.535-9.489C1.059 9.541 9.575 1.027 20.041 1.027c5.079.002 9.845 1.979 13.43 5.566a18.86 18.86 0 0 1 5.556 13.428c-.004 10.465-8.522 18.98-18.986 18.98h-.008c-3.177-.001-6.3-.798-9.073-2.311L.895 39.33z" />
                <path d="M.895 39.83a.5.5 0 0 1-.482-.631l2.639-9.636a19.48 19.48 0 0 1-2.497-9.556C.559 9.265 9.3.527 20.041.527c5.21.002 10.105 2.031 13.784 5.713a19.36 19.36 0 0 1 5.702 13.781c-.004 10.741-8.746 19.48-19.486 19.48-3.189-.001-6.344-.788-9.144-2.277l-9.875 2.589c-.042.012-.084.017-.127.017z" />
            </g>
            <path
                d="M31.203 8.859a15.67 15.67 0 0 0-11.157-4.626c-8.704 0-15.783 7.076-15.787 15.774-.001 2.981.833 5.883 2.413 8.396l.376.597-1.595 5.821 5.973-1.566.577.342a15.75 15.75 0 0 0 8.032 2.199h.006c8.698 0 15.777-7.077 15.78-15.776a15.68 15.68 0 0 0-4.618-11.161z"
                fill="#fff"
            />
            <path
                d="M15.295 12.072c-.355-.79-.729-.806-1.068-.82l-.909-.011c-.316 0-.83.119-1.265.594s-1.661 1.622-1.661 3.956 1.7 4.59 1.937 4.906 3.282 5.259 8.104 7.161c4.007 1.58 4.823 1.266 5.693 1.187s2.807-1.147 3.202-2.255.395-2.057.277-2.255-.435-.316-.909-.554-2.807-1.385-3.242-1.543-.751-.237-1.068.238-1.225 1.543-1.502 1.859-.554.357-1.028.119-2.002-.738-3.815-2.354c-1.41-1.257-2.362-2.81-2.639-3.285s-.03-.731.208-.968c.213-.213.474-.554.712-.831s.316-.475.474-.791.079-.594-.04-.831-1.039-2.584-1.461-3.522z"
                fillRule="evenodd"
                fill="currentColor"
            />
        </svg>
    );
};
